import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["scroller", "item", "previousButton", "nextButton"]
  static values = {
    debug: Boolean
  }

  connect() {
    this.#debug("connected")
    this.currentItems = undefined
    this.currentItem = undefined

    this.#listen()
    this.#synchronize()
  }

  #listen() {
    this.scrollerTarget.addEventListener(
      "scrollend",
      this.#synchronize.bind(this)
    )
  }

  #synchronize() {
    this.#debug("sync")
    this.#setCurrentItems()
    this.#updateControls()
  }

  #setCurrentItems() {
    this.currentItems = this.itemTargets.filter((item) =>
      this.#isIntersecting(item)
    )
  }

  #updateControls() {
    this.previousButtonTarget.disabled = this.#atStart
    this.nextButtonTarget.disabled = this.#atEnd
  }

  next() {
    if (this.#atEnd) return
    if (!this.#currentItem) return
    this.#debug("next")
    const nextItem = this.#currentItem.nextElementSibling
    if (nextItem) {
      this.#goToItem(nextItem)
      this.currentItem = nextItem
    }
  }

  previous() {
    if (this.#atStart) return
    if (!this.#currentItem) return
    this.#debug("previous")
    const previousItem = this.#currentItem.previousElementSibling
    if (previousItem) {
      this.#goToItem(previousItem)
      this.currentItem = previousItem
    }
  }

  #goToItem(item) {
    const delta = Math.abs(this.scrollerTarget.offsetLeft - item.offsetLeft)

    const pos =
      this.scrollerTarget.clientWidth / 2 > delta
        ? delta - this.#scrollerPadding
        : delta + this.#scrollerPadding

    this.scrollerTarget.scrollTo(pos, 0)

    this.dispatch("scroll", {
      detail: { atEnd: this.#atEnd, atStart: this.#atStart }
    })
  }

  #debug(message) {
    if (!this.debugValue) return
    console.log(message)
  }

  #isIntersecting(item) {
    const scrollerRect = this.scrollerTarget.getBoundingClientRect()
    const rect = item.getBoundingClientRect()
    return rect.x > 0 && rect.x + rect.width < scrollerRect.width
  }

  get #currentItem() {
    return this.currentItems[0]
  }

  get #atEnd() {
    return this.currentItems.includes(this.itemTargets.slice(-1)[0])
  }

  get #atStart() {
    return this.currentItems.includes(this.itemTargets[0])
  }

  get #scrollerPadding() {
    return parseInt(getComputedStyle(this.scrollerTarget)["padding-left"])
  }
}
